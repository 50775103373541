<template>
    <div class="card" style="margin-bottom:0;">
        <div class="card-header card-header-icon">
            <div class="card-icon bg-deep-blue" style="padding: 5px 10px 5px;cursor:pointer">
                <!-- <h4 class="card-title text-white" @click="clickTicket" v-if="this.parte.folio > 0">Folio {{ this.parte.folio }}</h4>
                <h4 class="card-title text-white" @click="clickTicket" v-else>Parte Interno</h4> -->
                <h4 class="card-title text-white" @click="clickTicket">{{ `Folio: ${this.parte.folio} (${tipo_parte})` }}</h4>
            </div>
        </div>
        <div class="card-body">
            <div class="table-responsive">
                <table class="table" style="margin-bottom:0;">
                    <tbody>
                        <tr v-if="this.parte.tipo_origen">
                            <td>Wellboat</td>
                            <td>{{ this.parte.wellboat.nombre }}</td>
                        </tr>
                        <tr v-if="this.parte.tipo_origen">
                            <td>Punto de origen</td>
                            <td>{{ this.parte.tipo_origen.nombre }}</td>
                        </tr>
                        <tr v-if="this.parte.empresa">
                            <td>Cliente</td>
                            <td>{{ this.parte.empresa.razon_social }}</td>
                        </tr>
                        <tr v-if="this.parte.origen">
                            <td>Origen</td>
                            <td>{{ this.parte.origen.nombre }}</td>
                        </tr>
                        <tr v-if="this.parte.destino">
                            <td>Destino</td>
                            <td>{{ this.parte.destino.nombre }}</td>
                        </tr>
                        <tr v-if="this.parte.zarpe">
                            <td>Zarpe</td>
                            <td>{{ this.moment(this.parte.zarpe).format("DD-MM-YYYY HH:mm")}}</td>
                        </tr>
                        <tr v-if="this.parte.zarpe">
                            <td colspan="2"><a @click="clickDelete"><i class="material-icons">delete</i></a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<style scoped>
  .table th, .table td{
    padding: 0;
  }
  .card .card-header.card-header-icon .card-title{
    margin-top: 0;
  }
  .card .card-body{
    padding: 0;
  }
</style>
<script>
export default {
    props: ['parte'],
    data:()=>({
        tipo_partes:[
            {id:1,nombre:'Cosecha'},
            {id:2,nombre:'Interno'},
            {id:3,nombre:'Smolt'},
            {id:4,nombre:'Mortalidad'},
            {id:5,nombre:'Baño'},
            {id:6,nombre:'Vacuna'},
            {id:7,nombre:'Muestreo'},
        ]
    }),
    methods: {
        clickTicket(){
            this.$emit('clicked', this.parte);
        },
        clickDelete(){
            this.$emit('deleted', this.parte);
        }
    },
    computed:{
        tipo_parte(){
            return this.tipo_partes.find(tp => tp.id == this.parte.tipopartes_id).nombre
        }
    }
}
</script>